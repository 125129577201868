import axios, { AxiosError, isAxiosError as isInternalAxiosError } from 'axios'

export default axios.create({
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest',
	},
	withCredentials: true,
})

export function isAxiosError<T = unknown>(error: unknown): error is AxiosError<T> {
	return isInternalAxiosError(error)
}
